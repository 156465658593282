export const Strings = {
    otp_verification: "OTP Verification",
    otp_msg: "Please enter the OTP sent to your mobile number",
    otp: "OTP",
    otpmsg: "Enter OTP (OTP દાખલ કરો)",
    otp_verify: "Verify OTP",
    donthaveaccount: "Don't have an account?",
    signup: "Sign Up",
    phone_validation: "Enter Mobile Number",
    sucessmsg:'Member Registered Successfully',
    errormsg:'Member already Exist',
    surname: 'Surname',
    country: 'Country',
    select_country: 'Select Country',
    village: 'Village',
    select_village: 'Select Village (ગામ પસંદ કરો)',
    pragati: 'Pragati',
    select_pragati: 'Select Pragati',
    relation: 'Relation',
    web_address:'www.27samaj.com',
    next:'next',
    terms:'I agree to the Terms and Conditions (હું શરતો અને નિયમો ને સ્વીકારું છુ)',
    new_member:'New Member Registration',
    web_address_link:'http://www.27samaj.com',
    loginaccount:'Log In Your Account',
    login:'Login',
    loginmsg:'Enter your Mobile Number to Get OTP',
    mobile_number_validation:'Enter Mobile Number (ફોન નંબર)',
    signin:'Sign in',
    signup:'Sign up',
    registernow: 'Register Now (નોંધણી કરો)',
    donthaveaccount:"Didn't have an account? ",
    select_terms_validation:'please agree terms and condition',
    password_validation:'please enter correct password',
    otp_verification:'OTP Verification',
    otp:'OTP',
    otp_verify:'Verify OTP ',
    otpmsg:'Please enter OTP',
    errorlogin:'Not Registered Member',
    otp_msg:'Please enter OTP Verification Number sent to your mobile number and email ID.',
    did_got_otp:"Didn't get OTP?",
    resend_otp:'Resend OTP',
    male:'Male',
    female:'Female',
    option1:'option1',
    option2:'option2',
    surname_validation:'Please enter surname',
    name_validation:'Please enter your name',
    father_name_validation:"Please enter father`s name",
    grand_father_name_validation:"Please enter grandfather`s name",
    birth_validation:'Please select birth date',
    phone_validation:'Please enter phone number',
    email_validation:'Please enter email',
    address_validation:'Please enter address',
    sakh_validation:'please select sakh',
    village_validation:'please select village',
    country_validation:'please select country',
    agree_terms:'please agree the terms and condition',
    phone_number:'Phone Number (ફોન નંબર)',
    zero:'000000',
    surname_required:'Surname (અટક) *',
    name_required:'Your Name (નામ) *',
    father_required:'Father/Husband Name(પિતા/પતિનું નામ)*',
    grand_father_required:"Grand Father's Name (દાદાનું નામ) *",
    phone_required:'Phone Number (ફોન નંબર) *',
    email:'Email (ઈમેલ)',
    residence_address:'Residence Address',
    sakh:'Sakh',
    sakh_select:'Select Sakh',
    village:'Native (ગામ)',
    marraige_beuro:'Marraige Beuro(લગ્ન વિષયક)',
    village_select:'Select Native (ગામ)',
    search:'Search Member',
    search1:'Search (શોધ)',
    country:'Country (દેશ)',
    country_select:'Select Country (દેશ)',
    agree_terms_msg:'I agree to the Terms and Conditions (હું નિયમો અને શરતોથી સંમત છું)',
    register:'Registration (નવા સભ્યની નોંધણી)',
    regtitle:'Welcome !',
    welcome_reg: 'Welcome to Registration',
    sucessmsg:'Member Registered Successfully',
    errormsg:'Member already Exist',
    regdescrption:'Use these awesome forms to login or create new account in your Rami samaj website.',
    alread_account_msg:'Already have an account? ',
    no_data:"oops! There's no data here!",
    list_surname:'List Of Family Surname(Sakh)',
    fail_fetch_village:'Failed to fetch village gallery. Please try again.',
    member_detail:'Member Detail',
    image:'Image',
    name:'Name (નામ)',
    age_from:'Age From (ઉંમર થી)',
    select_age_from:'Select Age From (ઉંમર થી)',
    age_to:'Age To (ઉંમર સુધી)',
    select_age_to:'Select Age To (ઉંમર સુધી)',
    marital_status:'Marital Status (વૈવાહિક સ્થિતિ)',
    select_marital_status:'Select Marital Status (વૈવાહિક સ્થિતિ)',
    blood_group:'Blood Group (બ્લડ ગ્રુપ)',
    select_blood_group:'Select Blood Group (બ્લડ ગ્રુપ)',
    education_type:'Education Type (શિક્ષણ પ્રકાર)',
    matrimonail_member:'Marriage Beuro (લગ્ન બ્યુરો)',
    select_education_type:'Select Education Type (શિક્ષણ પ્રકાર)',
    education_sub_type:'Education Sub Type (શિક્ષણ પેટા પ્રકાર)',
    select_education_sub_type:'Select Education Sub Type (શિક્ષણ પેટા પ્રકાર)',
    current_country:'Current Country (હાલનો દેશ)',
    select_current_country:'Select Current Country (હાલનો દેશ)',
    occupation:'Occupation (વ્યવસાય)',
    select_occupation:'Select Occupation (વ્યવસાય)',
    visa_type:'Visa Type (વિઝા પ્રકાર)',
    select_visa_type:'Select Visa Type (વિઝા પ્રકાર)',
    successfully:'Successfully',
    whatsapp_install_msg:'Make sure Whatsapp installed on your device',
    title_colon:'Title (શીર્ષક)',
    url_colon:'URL: ',
    gender:'Gender (જાતિ)',
    dob:'Birth Date (જન્મ તારીખ)',
    date:'Date (તારીખ)',
    age:'Age',
    education_name:'Education (શિક્ષણ પ્રકાર)',
    residence_address:'Residence Address (રહેઠાણ) ',
    amount : 'Amount (રકમ)',
    Donation_category:'Donation category (દાન શ્રેણી)',
    Select_Donation_category:'Select Donation category (દાન શ્રેણી)',
    company_name:'Company Name (કંપનીનું નામ)',
    designation:'Designation (હોદ્દો)',
    occupation_address:'Occupation Address (વ્યવસાયનું સરનામું)',
    mosal:'Mosal (મોસાળ)',
    mama_name:'Mama Name (મામાનું નામ)',
    mothername: 'Mother Name ( માતાનું નામ)',
    city_location:'City/Location (શહેર)',
    pragati_mandal:'Pragati Mandal',
    height:'Height (ઊંચાઈ)',
    weight:'Weight (વજન)',
    annual_income:'Annual Income (વાર્ષિક આવક)',
    matrimonial_interest:'Interested Matrimonial (વૈવાહિક રસ ધરાવે છે) ? ',
    blood_donate_interest:'Intrested for blood donation (રક્તદાન માટે રસ ધરાવો છો) ?',
    about_me:'About Me (મારા વિશે)',
    business_name:'Business Name (વ્યવસાય નામ)',
    concern_person:'Concern Person (વ્યક્તિનું નામ)',
    contact:'Contact (અમારો સંપર્ક કરો)',
    select_business_category:'Select Business Category (વ્યવસાય કેટેગરી)',
    business_category:' Business Category (વ્યવસાય કેટેગરી)',
    category:'Category (શ્રેણી)',
    description:'Description (વર્ણન)',
    city:'City (શહેર)',
    select_city:'Select City (શહેર)',
    PIN:'PIN (પિન કોડ)',
    age_colon:'Age (ઉંમર)',
    matrimonial_member_braces:'Matrimonial Member (',
    validation_msg_business_name:'Please enter business name.',
    validation_msg_concern:'Please enter concern person.',
    validation_msg_business_detail:'Please enter about business detail.',
    validation_msg_business_full_address:'Please enter full address.',
    validation_msg_business_category:'Please Select Business Category',
    validation_msg_Pin:'Please enter PIN.',
    validation_msg_city:'Please Select City',
    validation_msg_contact_number1:'Please enter Contact Number 1.',
    validation_select_image:'Please select image',
    user_cancelled_upload:'User Cancelled the upload',
    select_gallery:'Select from Gallery',
    take_photo:'Take Photo',
    cancel:'Cancel',
    about_business_detail:'About Business Details (વ્યવસાયની વિગત )',
    full_address:'Full Address (પૂરું સરનામું)',
    address:'Address (સરનામું)',
    contact_number1:'Contact Number 1',
    contact_number2:'Contact Number 2',
    website:'Website (વેબસાઇટ)',
    location_google_map:'Location(Google Map Link) (ગૂગલ મેપ લિંક)',
    brochure_View_Download :'Brochure(View/Download)',
    catelog_brochure:'Catelog/Brochure (પુસ્તિકા)',
    attach_file:'Attach File',
    social_media_links:'Social Media Links',
    youtube_link:'Youtube Link (યુટ્યુબ લિંક)',
    facebook_link:'Facebook Link (ફેસબુક લિંક)',
    linkedin_link:'LinkedIn Link (LinkedIn લિંક)',
    instagram_link:'Instagram Link (ઇન્સ્ટાગ્રામ લિંક)',
    telegram_link:'Telegram Link (ટેલિગ્રામ લિંક)',
    business_image1:'Business Image 1',
    business_catalogue:'Business Catalogue (બિઝનેસ કેટલોગ)',
    submit:'Submit',
    pay:'Pay Now',
    ok: "Ok",
    pay_online :'pay online',
    validation_msg_contact:'Please enter contact',
    enter_title:'Please enter title',
    enter_message:'Please enter message',
    inquiry_sucess:'Inquiry Added Successfully',
    title:'Title (શીર્ષક)',
    message:'Message (સંદેશ)',
    validation_last_name:'Please enter last name',
    validation_village:'Please select village',
    validation_relation:'Please select relation',
    validation_marital_status:'Please enter marital status',
    validation_eduction:'Please select education',
    validation_sub_eduction:'Please select sub education',
    validation_occupation:'Please select occupation',
    validation_country:'Please select country',
    validation_blood_group:'Please select blood group',
    validation_mosal:'Please select mosal',
    validation_pragatimandal:'Please select pragatimandal',
    validation_height:'Please select height',
    validation_weight:'Please select weight',
    validation_contact:'Please enter contact',
    validation_relation_type:'Select Relation Type (સંબંધ પ્રકાર પસંદ કરો)',
    relation_type:'Relation Type (સંબંધ પ્રકાર પસંદ કરો)',
    dob_member:'Date of Birth (જન્મ તારીખ)',
    select_image: 'Please Select Image',
    company_dep:'Company/Department Name (કંપની/વિભાગનું નામ)',
    mosal_village:'Mosal Village (મોસાળ)',
    select_mosal_village:'Select Mosal Village (મોસાળ)',
    mosal_required:'Mosal*',
    select_pragati_mandal:'Select Pragati Mandal',
    height_foot:'Height(Foot)',
    select_weight:'Select Weight',
    height_inches:'Height(Inches)',
    image_1:'Image 1',
    image_2:'Image 2',
    image_3:'Image 3',
    update:'Update',
    updatemember:'Update Member',
    validation_select_image2:'Please select image 2',
    validation_select_image3:'Please select image 3',
    validation_select_image4:'Please select image 4',
    business_image2:'Business Image 2',
    business_image3:'Business Image 3',
    business_image4:'Business Image 4',
    pick_blood_type:'Please pick your blood type',
    diamond_donor:'Diamond Donor',
    platinum_donor:'Platinum Donor',
    golden_donor:'Golden Donor',
    silver_donor:'Silver Donor',
    add_member:'Member Registration (સભ્યની નોંધણી)',
    marriage_beuro:'Marriage Beuro(લગ્ન વિષયક)',
    besnu:'Besnu (બેસણું)',
    business:'Business (બિઝનેસ)',
    blood_donors:'Blood Donors (રક્તદાતાઓ)',
    news:'News (સમાચાર)',
    family:"Family's (પરિવાર)",
    my_family_members:'My Family Members',
    family_member:'Family Members (મારા પરિવારના સભ્યો)',
    contact_us:'Contact Us (અમારો સંપર્ક કરો)',
    dashboard:'Dashboard (હોમ)',
    donors:'Donors',
    bhagini_sanasthas:'Committee List (સમિતિ યાદી)F',
    msg_guj:'શ્રી પાટણવાડા રામી - માળી સમાજ મહેસાણા - અમદાવાદ    ',
    msg_quit:'Do you want to Pay Now in Rami Samaj ? ',
    member_death_msg: 'Member is not alive',
    msg_marquee:'Your profile is not approved by admin. Contact admin for approval.(તમારી પ્રોફાઇલ એડમીનશ્રી તરફથી એપ્રુવ થયેલ નથી .એપ્રુવ કરવા માટે એડમીનશ્રી નો સંપર્ક કરો)',
    home:'Home',
    my_business:'My Business (મારો બિઝનેસ)',
    manage_business:'Manage Business (બિઝનેસ ઉમેરો)',
    add_manage_business:'Add Business (વ્યવસાય ઉમેરો)',
    update_manage_business:'Update Business',
    marriage_beuro:'Marriage Beuro(લગ્ન વિષયક)',
    inquiry:'Query (પ્રશ્ન)',
    app_donors:'App Donors (એપ્લિકેશન દાતાઓ)',
    app_donor_category:'App Donor Category',
    my_profile:'My Profile',
    profile:'Profile (મારી પ્રોફાઇલ)',
    kul_devi:'Kul Devi',
    partidar_history:'History (ઇતિહાસ)',
    photo_gallery:'Photo Gallery (ફોટો ગેલેરી)',
    video_gallery:'Video Gallery (વિડિઓ ગેલેરી)',
    village_gallery:'Village Gallery',
    check_for_updates:'Check for updates',
    share:'Share',
    about_us:'About Us (અમારા વિશે)',
    logout:'Logout',
    pragatimandal:'Pragatimandal',
    filter_business:'Filter Business',
    business_filter:'Business Filter',
    matrimonial_member_filter:'Matrimonial Member Filter',
    blood_donors_filter:'Blood Donors Filter',
    my_business_filter:'My Business Filter',
    family_head_filter_list:'Family Head Filter List',
    village_sakh:'Village/Sakh',
    matrimonial_member:'Matrimonial Member',
    edit_business:'Edit Business',
    family_head_list:'Family Head List',
    filter_members:'Filter Members',
    besnu_detail:'Besnu Detail',
    business_detail:'Business Detail',
    search_result:'Search Result',
    news_detail:'News Detail',
    jm:'Mryutyu sahay yojna (મૃત્યુ સહાય યોજના)',
    donate:'Donation (દાન)',
    JMMembershipId:'JM Membership Id',
    RegistrationDate:'Registration Date (તારીખ)',
    JMmember :'Be A JM Member',
    about_JMmember:'About Mryutyu Sahay Yojna (મૃત્યુ સહાય યોજના વિશે)',
    donation_JMmember:'Donation For JM Death   (JM સભ્ય મૃત્યુ માટે દાન)',
    paidhistory: 'Paid history - mryutyu sahay (પેમેન્ટ વિગત)',
    Alljmmembers:'All mryutyu sahay Yojna Member (મૃત્યુ સહાય યોજનાના તમામ સભ્ય)',
    Doners:'Doners (દાતાઓ)',
    Make_Donation:'Make Donation (દાન કરો)',
    Donation_List:'My Previous Donation List (મારા અગાઉના દાન)',
    besanu_date:'Besanu Date (બેસણું તારીખ)',
    death_date: 'Death Date (મરણ તારીખ)',
    besanu_place: 'Besanu Place (બેસણું સ્થળ)',
    besanu_time: 'Besanu Time (બેસણું સમય)' ,
    shokatur: 'Shokatur (શોકાતુર)' ,
    besanu_description: 'Besanu Description (બેસણું વર્ણન)',
    news_desc:'News Description (સમાચાર વર્ણન)',
    already_account: 'Already Account',
    sign_in: 'Sign In',

    //new project data 

    ListOfMembers: 'List Of Members (સભ્યોની યાદી)',
    DonorImage: 'Donor Image (દાતાની છબી)',
    DonorType: 'Donor Type (દાતા પ્રકાર)',
    VillageName: 'Village Name (ગામનું નામ)',
    DonorName: 'Donor Name (દાતાનું નામ)',
    Contact: 'Contact (સંપર્ક કરો)',
    Remarks: 'Remarks (ટીકા)',
    NoDataFound: 'No Data Found (કોઈ ડેટા મળ્યો નથી)',
    BesnuDetailOf: 'Besnu Detail Of ',
    MemberListOf: 'Member List Of   ',
    BloodGroup: ' Blood Group',
    Photo:'Photo (ફોટો)',
    Name:'Name (નામ)',
    City:'City (શહેર)',
    Contact: 'Contact (સંપર્ક કરો)',
    Alive: 'Alive (જીવંત)',
    Action: 'Action (ક્રિયા)',
    BloodGroupList: 'Blood Donors (રક્તદાતાઓ)',
    MoreInformationClickHere: 'More Information Click Here (વધુ માહિતી અહીં ક્લિક કરો)',
    Business: 'Business (બિઝનેસ)',
    Address: 'Address (સરનામું)',
    Category: 'Category (શ્રેણી)',
    BusinessDetailOf: 'Business Detail Of ',
    Nobusinessdetailsfound: 'No business details found. (કોઈ વ્યવસાય વિગતો મળી નથી.)',
    ClickForMore: 'Click For More (વધુ માટે ક્લિક કરો)',
    CommitteeMemberList: 'Committee Member List (સમિતિના સભ્યોની યાદી)',
    Image: 'Image (છબી)',
    CommitteeName: 'Committee Name (સમિતિનું નામ)',
    Village: 'Village (ગામ)',
    Designation: 'Designation (હોદ્દો)',
    Dashboard: 'Dashboard (હોમ)',
    AboutUs: 'About Us (અમારા વિશે)',
    DashboardAbout: 'The Mali are an occupational caste found among the Hindus who traditionally worked as gardeners and florists. They also call themselves Phul Mali due to their occupation of growing flowers. The Mali are found throughout North India, East India as well as the Terai region of Nepal and Maharashtra. Iravati Karve, an anthropologist, showed how the Maratha caste was generated from Kunbis who simply started calling themselves Maratha. She states that Maratha, Kunbi and Mali are the three main farming communities of Maharashtra – the difference being that the Marathas and Kunbis were dry farmers whereas the Mali farmed throughout the year.',
    Date: 'Date (તારીખ)',
    Amount: 'Amount (રકમ)',
    Native: 'Native (ગામ)',
    DonationList: 'Donation List (દાન યાદી)',
    Donations: 'Donations (દાન)',
    DonationDonorsList: 'Donors List (દાતાઓની યાદી)',
    MyPreviousDonation: 'My Previous Donation (મારું અગાઉનું દાન)',
    DonorName: 'Donor Name (દાતાનું નામ)',
    Surname: 'Surname (અટક)',
    Relation: 'Relation (સંબંધ)',
    Education: 'Education (શિક્ષણ)',
    Occupation: 'Occupation (વ્યવસાય)',
    Age: 'Age (ઉંમર)',
    Status: 'Status (સ્થિતિ)',
    FamilyMembers: 'Family Members (પરિવારના સદસ્યો)',
    View: 'View (જુઓ)',
    Edit: 'Edit (સંપાદિત કરો)',
    PrivacyPolicy: 'Privacy Policy',
    TermsConditions: 'Terms & Conditions',
    Copyright: 'Copyright 2024 | Rami Samaj All Rights Reserved',
    mainTitle: '27Samaj',
    SignIn: 'Sign In (સાઇન ઇન કરો)',
    SignUp: 'Sign Up (સાઇન અપ કરો)',
    JMDeathList: 'JM Death List (જેએમ મૃત્યુ યાદી)',
    JMMembers: 'JMMembers (જેએમએમ સભ્યો)',
    MakeaDonation: 'Make a Donation (દાન કરો)',
    ManageBusiness: 'Manage Business (બિઝનેસ ઉમેરો)',
    Adding: 'Adding...',
    DetailOf: 'Detail Of ',
    Single: 'Single (અપરિણીત)',
    Married: 'Married (પરણિત)',
    Widowed: 'Widowed (વિધવા)',
    Engaged: 'Engaged (એન્ગેજડ)',
    Separated: 'Separated (અલગ)',
    Divorced: 'Divorced (છૂટાછેડા)',
    Widower: 'Widower (વિધુર)',
    NotInterested: 'Not Interested (રસ નથી)',
    Pleasefathername: 'Please enter a fathername',
    Pleaseaddress: 'Please enter a residence address',
    Pleaseoccupation: 'Please enter a occupation',
    Pleasedesignation: 'Please enter a designation',
    Pleaseaboutme: 'Please enter a about me',
    Pleasecountry: 'Please enter a country',
    Pleasename: 'Please enter a name',
    Pleasecompanyname: 'Please enter a companyname',
    Pleaseoccaddress: 'Please enter a occupation address',
    Pleasenative: 'Please enter a native',
    Pleaseemail: 'Please enter a email',
    PleaseselectNative: 'Please select Native.',
    Pleasemothername: 'Please enter mother name',
    Pleasebloodgroup: 'Please enter blood group',
    MemberRegisterForm: 'Member Register Form',
    RelationType: 'Relation Type',
    SelectImage: 'Select Image (છબી પસંદ કરો)',
    SelectCatelog: 'Select Catelog',
    SelectRelationType: 'Select Relation Type',
    FirstName: 'First Name (પ્રથમ નામ)',
    LastName: 'Last Name (છેલ્લું નામ)',
    GrandFatherName: 'Grand Father Name',
    Phone: 'Phone', 
    Email: 'Email',
    DateofBirth: 'Date of Birth',
    SelectVillage: 'Select Village (ગામ પસંદ કરો)',
    SelectMosalVillage: 'Select Mosal Village',
    PayNow: 'Pay Now',
    PleaseEnterDate: 'Please Enter Date.',
    PleaseEnteryourAmount: 'Please Enter your Amount.',
    PleaseSelectDonationCategory: 'Please Select Donation Category.',
    Pleaseenterabusinessname: 'Please enter a business name',
    Pleaseenteraconcernperson: 'Please enter a concern person',
    Pleaseenteradescription: 'Please enter a description',
    Pleaseenteracategory: 'Please enter a category',
    Pleaseenteraaddress: 'Please enter a address',
    Pleaseenterapin: 'Please enter a pin',
    Pleaseenteracity: 'Please enter a city',
    Pleaseenteracontact: 'Please enter a contact',
    Pleaseenterawebsite: 'Please enter a website',
    Pleaseenteralocation: 'Please enter a location',
    Pleaseenteraimage: 'Please enter a image',
    Pleaseselectbusinessyoutube: 'Please select business youtube',
    Pleaseselectbusinesstelegram: 'Please select business telegram',
    Pleaseenterabusinessfacebook: 'Please enter a business facebook',
    Pleaseenterabusinessinsta: 'Please enter a business insta',
    Pleaseenterbusinesslinkedin: 'Please enter business linkedin',
    Pleaseenterbusinesscatalogue: 'Please enter business catalogue',
    MyBusinessDetailOf: 'My Business Detail Of ',
    EDIT: 'EDIT (સંપાદિત કરો)',
    Nobusinessdetailsfound: 'No business details found. (કોઈ વ્યવસાય વિગતો મળી નથી.)',
    Prev: 'Prev (પૂર્વ)',
    Next: 'Next (આગળ)',
    News: 'News (સમાચાર)',
    LearnMore: 'Read More',
    SuccessfullyLogin: 'Successfully Login (સફળતાપૂર્વક લૉગિન)',
    Pleaseentervalidotp: 'Please enter valid otp (કૃપા કરીને માન્ય ઓટીપી દાખલ કરો)',
    PaidHistory: 'Paid History (ચૂકવેલ ઇતિહાસ)',
    Profile: 'Profile (પ્રોફાઇલ)',
    Somethingwentwrong: 'Something went wrong (કંઈક ખોટું થયું)',
    MemberRegisterForm: 'Member Register Form (સભ્ય નોંધણી ફોર્મ)',
    RelationType: 'Relation Type (સંબંધનો પ્રકાર)',
    SelectRelationType: 'Select Relation Type (સંબંધ પ્રકાર પસંદ કરો)',
    Updating: 'Updating...',
    InquiryNotSubmitted: 'Inquiry Not Submitted (પૂછપરછ સબમિટ નથી)',
    InquirySubmitted: 'Inquiry Submitted (પૂછપરછ રજૂ કરી)',
    Errorsubmittinginquiry: 'Error submitting inquiry (તપાસ સબમિટ કરવામાં ભૂલ)',
    Unknownerror: 'Unknown error (Unknown error)',
    MemberSearch: 'Member Search (સભ્ય શોધ)',
    SelectCountry: 'Select Country (Select Country)',
    SelectGender: 'Select Gender (લિંગ પસંદ કરો)',
    Male: 'Male (પુરુષ)',
    Female: 'Female (સ્ત્રી)',
    FromAge: 'From Age (ઉંમર થી)',
    ToAge: 'To Age (ઉંમર માટે)',
    SelectBloodGroup: 'Select Blood Group (બ્લડ ગ્રુપ પસંદ કરો)',
    SelectEducation: 'Select Education (શિક્ષણ પસંદ કરો)',
    SelectEducationSubType: 'Select Education Sub Type (શિક્ષણ પેટા પ્રકાર પસંદ કરો)',
    SelectOccupation: 'Select Occupation (વ્યવસાય પસંદ કરો)',
    SelectPragatiMandal: 'Select Pragati Mandal (પ્રગતિ મંડળ પસંદ કરો)',
    SelectMaritalStatus: 'Select Marital Status (વૈવાહિક સ્થિતિ પસંદ કરો)',
    SelectNative: 'Select Native (મૂળ પસંદ કરો)',
    Search: 'Search (શોધો)',
    MemberList: 'Member List (સભ્ય યાદી)',
    EnterMobileNumber: 'Enter Mobile Number (મોબાઈલ નંબર દાખલ કરો)',
    ValidMobileNumber: 'Enter a valid mobile number (માન્ય મોબાઇલ નંબર દાખલ કરો)',
    PleaseacceptTermsConditions: 'Please accept Terms & Conditions (કૃપા કરીને નિયમો અને શરતો સ્વીકારો)',
    Login: 'Login (પ્રવેશ કરો)',
    EnteryourMobileNumbertoGetOTP: 'Enter your Mobile Number to Get OTP',
    IagreetotheTermsandConditions: 'I agree to the Terms and Conditions',
    SignIn: 'Sign In (સાઇન ઇન કરો)',
    Donthaveanaccount: "Don't have an account?  ",
    Registration: 'Registration (નવા સભ્યની નોંધણી)',
    Checktermbeforesubmitting: 'Check term before submitting (સબમિટ કરતા પહેલા મુદત તપાસો)',
    Events: 'Events (ઘટનાઓ)',
    NotableDeaths: 'Notable Deaths (નોંધપાત્ર મૃત્યુ)',
    ImgNotFound: 'Img Not Found (આઇએમજી મળી નથી)',
    EnterSurname: "Enter Surname (અટક દાખલ કરો)",
    EnterFirstName: 'Enter First Name (પ્રથમ નામ દાખલ કરો)',
    EnterLastName: 'Enter Last Name (છેલ્લું નામ દાખલ કરો)',
    EnterGrandFatherName: 'Enter Grand Father Name (દાદાનું નામ દાખલ કરો)',
    male: 'male (પુરૂષ)',
    female: 'female (સ્ત્રી)',
    role: 'role',
    EnterPhoneNumber: 'Enter Phone Number (ફોન નંબર દાખલ કરો)',
    EnterEmail: 'Enter Email (ઈમેલ દાખલ કરો)',
    Besnu: 'Besnu (બેસણું)',
    Money: 'Money (પૈસા)',
    InterestBloodDonation: 'Interest Blood Donation (રસ રક્તદાન)',
    Location: 'Location',
    city_location_validation: "Please enter a city location (કૃપા કરીને શહેરનું સ્થાન દાખલ કરો)",
    FatherorHusbandName: "Father/Husband Name(પિતા/પતિનું નામ)",
    Other: "Other અન્ય",
    Donation: "Donation Amount",
    FatherName: "Father Name",
    Gender: "Gender",
  };