import { useState, useEffect } from "react";
import {
  Card,
  IconButton,
  Grid,
  Typography,
  Box,
  Modal,
  Button,
  Tooltip,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Drawer,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Strings } from "../Common/String";
import { MyBusinessAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import nodatafound from '../assets/background/nodatafound.png';
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
  Telegram as TelegramIcon,
  LinkedIn as LinkedInIcon,
  Description as DescriptionIcon,
  LocationOn as LocationOnIcon,
  PinDrop as PinDropIcon,
  Call as CallIcon,
  BusinessCenter as BusinessCenterIcon,
  Language as LanguageIcon,
  Email as EmailIcon,
  MenuBook as MenuBookIcon,
} from "@mui/icons-material";

function Business() {
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectPhoto, setSelectPhoto] = useState('');
  const navigate = useNavigate();

  const handleOpen = (e, datas) => { 
    console.log('dartass ', datas)
    setOpen(true);
    setSelectPhoto(datas);
  }
  const handleOpen1 = () => {
    console.log('ee');
  }
  const handleClose = () => setOpen(false);

  // const handleDrawerOpen = (business) => {
  //   setSelectedBusiness(business);
  //   setDrawerOpen(true);
  // };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    pt: 2,
    px: 4,
    pb: 3,
    border: "none",
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await MyBusinessAPI({
          business_name:'',
          category_id:'',
          business_owner_name:'',
          city_id:'',
          contact:'',
          country_id:'',
          family_member_id:'',
          offset: offset,
          approve_status:1,
          pin:'',
        });

        if (response.data) {
          const newRows = response.data.map((val) => ({
            photo: Imageurl.business_image + val.image_1,
            business: val.business_name,
            address: val.address,
            category: val.category_name,
            contact: val.gender == "female" ? "" : val.contact_1,
            action: val,
          }));

          setRows(newRows);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [offset]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (business) => {
    navigate('/businessdetail', { state: { business } });
  };

  const getIconColor = (category) => {
    switch (category) {
      case Strings.category:
        return "#ff9800"; // Orange
      case Strings.description:
        return "#4caf50"; // Green
      case Strings.address:
        return "#2196f3"; // Blue
      case Strings.city:
        return "#f44336"; // Red
      case Strings.PIN:
        return "#9c27b0"; // Purple
      case Strings.country:
        return "#673ab7"; // Deep Purple
      case Strings.contact_number1:
        return "#e91e63"; // Pink
      case Strings.contact_number2:
        return "#00bcd4"; // Cyan
      case Strings.website:
        return "#009688"; // Teal
      case Strings.email:
        return "#ff5722"; // Deep Orange
      case Strings.business_catalogue:
        return "#795548"; // Brown
      default:
        return "inherit"; // Default color
    }
  };

  return (
    <Card style={{ marginBottom: '30px' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Typography variant="h6" gutterBottom>
          {Strings.business}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>{Strings.Photo}</strong>
              </TableCell>
              <TableCell align="left">
                <strong>{Strings.Business}</strong>
              </TableCell>
              <TableCell align="left">
                <strong>{Strings.Address}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Category}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Contact}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{Strings.Action}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center">
                      <Tooltip title="View Photo">
                        <Avatar
                          src={row.photo}
                          alt="Business Photo"
                          // onClick={(e) => handleOpen(e, row)}
                          onClick={row.photo == "" ? handleOpen1 : (e) => handleOpen(e,row)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      {/* <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Button
                            style={{ marginLeft: "90%" }}
                            onClick={handleClose}
                          >
                            X
                          </Button>
                          <img
                            src={selectPhoto}
                            alt="Profile Image Modal"
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                        </Box>
                      </Modal> */}
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.business}</TableCell>
                  <TableCell align="left">{row.address}</TableCell>
                  <TableCell align="center">{row.category}</TableCell>
                  <TableCell align="center">{row.contact}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleViewDetails(row.action)}>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {rows?.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
           <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
        </Box>
      )}
 
 <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        style: {
          width: 500,
          zIndex: 1500,
          height: '100%',
          position: 'fixed',
        },
      }}
    >
      <Box p={2}>
        {selectedBusiness ? (
          <>
            <Box display="flex" justifyContent="center" mb={3}>
              <img src={Imageurl.business_image + selectedBusiness.image_1} alt="Business Photo" style={{ width: 300, height: 300, borderRadius: "50%" }} />
            </Box>
            <Typography variant="h5" align="center" style={{ fontWeight: 500 }}>{selectedBusiness.business_name}</Typography>
            <Box my={2}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <BusinessCenterIcon style={{ color: getIconColor(Strings.category) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.category}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.category_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <DescriptionIcon style={{ color: getIconColor(Strings.description) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.description}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <LocationOnIcon style={{ color: getIconColor(Strings.address) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.address}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.address}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <PinDropIcon style={{ color: getIconColor(Strings.city) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.city}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.city_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <PinDropIcon style={{ color: getIconColor(Strings.PIN) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.PIN}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.pin}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <LanguageIcon style={{ color: getIconColor(Strings.country) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.country}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.country_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <CallIcon style={{ color: getIconColor(Strings.contact_number1) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.contact_number1}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.contact_1}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <CallIcon style={{ color: getIconColor(Strings.contact_number2) }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><strong>{Strings.contact_number2}</strong></Typography>
                      <Typography variant="body2">{selectedBusiness.contact_2}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {selectedBusiness.website && (
                        <LanguageIcon style={{ color: getIconColor(Strings.website) }} />
                      )}
                    </Grid>
                    <Grid item>
                      {selectedBusiness.website && (
                        <>
                          <Typography variant="subtitle1"><strong>{Strings.website}</strong></Typography>
                          <a href={selectedBusiness.website} target="_blank" rel="noopener noreferrer">
                            <Typography variant="body2">{selectedBusiness.website}</Typography>
                          </a>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {selectedBusiness.email && (
                        <EmailIcon style={{ color: getIconColor(Strings.email) }} />
                      )}
                    </Grid>
                    <Grid item>
                      {selectedBusiness.email && (
                        <>
                          <Typography variant="subtitle1"><strong>{Strings.email}</strong></Typography>
                          <a href={`mailto:${selectedBusiness.email}`}>
                            <Typography variant="body2">{selectedBusiness.email}</Typography>
                          </a>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {selectedBusiness.catalogue && (
                        <MenuBookIcon style={{ color: getIconColor(Strings.business_catalogue) }} />
                      )}
                    </Grid>
                    <Grid item>
                      {selectedBusiness.catalogue && (
                        <>
                          <Typography variant="subtitle1"><strong>{Strings.business_catalogue}</strong></Typography>
                          <a href={Imageurl.business_catalogue + selectedBusiness.catalogue} target="_blank" rel="noopener noreferrer">
                            <Typography variant="body2">{Strings.brochure_View_Download}</Typography>
                          </a>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="center" mt={3}>
              <IconButton href={selectedBusiness.facebook} target="_blank">
                <FacebookIcon style={{ color: "#3b5998" }} />
              </IconButton>
              <IconButton href={selectedBusiness.youtube} target="_blank">
                <YouTubeIcon style={{ color: "#c4302b" }} />
              </IconButton>
              <IconButton href={selectedBusiness.instagram} target="_blank">
                <InstagramIcon style={{ color: "#e1306c" }} />
              </IconButton>
              <IconButton href={selectedBusiness.telegram} target="_blank">
                <TelegramIcon style={{ color: "#0088cc" }} />
              </IconButton>
              <IconButton href={selectedBusiness.linkedin} target="_blank">
                <LinkedInIcon style={{ color: "#0077b5" }} />
              </IconButton>
            </Box>
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
             <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
          </Box>
        )}
      </Box>
    </Drawer>

    {selectPhoto && selectPhoto.photo && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {selectPhoto && selectPhoto.photo ? (
              <img
                src={`${selectPhoto.photo}`}
                alt="Member's Photo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <PersonIcon style={{ fontSize: 80, color: "gray" }} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
}

export default Business;
